import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error"
import BookingRoute from './routes/BookingRoute';
// location, path, networkError
const errorLink = onError(({ grqphlqlErrors }) => {
  if(grqphlqlErrors) {
    grqphlqlErrors.map(({message}) => {
      alert(`Graphql error ${message}`)
    });
  }
})
const link = from([
  errorLink,
  new HttpLink({ uri: `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/graphql`})
])
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link
})
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/:shop_link",
    element: <BookingRoute />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <RouterProvider router={router} />   
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
