import { Button, Form, Input, InputNumber, message, Modal } from 'antd'
import React, { useEffect, useState } from 'react';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { GET_USERS_BY_PHONE, SIGNUP_USER } from '../../Graphql/Queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import axios from "axios";
import "./index.css"

const SigninUser = ({
    open,
    onCancel, 
    merchant,
    setUser,
    user,
    shopLink
}) => {
    const FormItem = Form.Item;
    const [form]  = Form.useForm();
    const [getUserDetails] = useLazyQuery(GET_USERS_BY_PHONE);
    const [ signUpUser ] = useMutation(SIGNUP_USER)
    const [ phoneNumber, setPhoneNumber ] = useState(null);
    const [ isNewUser, setIsNewUser ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ name, setName ] = useState(null);
    const [ showOTP, setShowOTP ] = useState(false);
    const [ messages, setMessage ] = useState(null);
    const [ errorMsg, setErrorMsg ] = useState(null);
    const [ OTP, setOTP ] = useState(null);
    const [ userData, setUserData ] = useState(null);
    const [ isUserFound, setIsUserFound ] = useState(true);
    const [ isWhatapp, setIsWhatsapp ] = useState(false);
    const [timeLeft, setTimeLeft] = useState(60);
    const [ showSendWhatsapp, setShowWhatsapp ] = useState(false);

    useEffect(() => {
		const timerId = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

		if(timeLeft <= 0){
			setShowWhatsapp(true);
			clearInterval(timerId);
		}

		if(!showOTP) clearInterval(timerId)
	
	  return () => {
		clearInterval(timerId);
	  }
	}, [timeLeft, showOTP]);

    const formatTime = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		//
		return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
	};

    const handleSignIn = () => {
		if(!phoneNumber) return message.error("Enter phone number to continue");

		setLoading(true);
		getUserDetails({ 
			variables: { 
			  phone_number: `${phoneNumber}`,
			  merchant_id: merchant.id
			}, 
			onCompleted: (data) => {
				const { message: mess, user } = data.getUserByPhone;
				if(mess === "failed, user does not exist!") {
					setIsNewUser(true);
					return setLoading(false);
				};
				
				setIsNewUser(false);
				setLoading(false);
				setUserData(user);
                generateOTP();
			}
		});
	}

    const handleSignUp = () => {
        if(!phoneNumber) return message.error("Enter phone number to continue");
        if(!name) return message.error("Enter your name to continue");
		setLoading(true);
		try {
            signUpUser({
                variables: {
                    data: {
                        first_name: name,
                        phone_number: phoneNumber,
                        merchant_rewards_id: shopLink || "",
                        sex: "-",
                    },
                },
                onCompleted: (data) => {
                    const { signUpUser } = data;
                    if(signUpUser) {
                        setLoading(false);
                        setUserData(signUpUser);
                        setIsNewUser(false);
                        generateOTP();
                    }
                }
            });
		} catch (error) {
			setLoading(false);
			message.error(error.message);
		}
	};

    const generateOTP = () =>{
		if(!phoneNumber) return message.error("Enter phone number to continue");
        
        const formData = new FormData();
        formData.append("phone_number", phoneNumber);
		setLoading(true);
        axios.put(`${process.env.REACT_APP_BASE_URL}/api/v2/generate_phone_verification_token`, formData)
        .then((res)=>{
            if(res.status === 200){
                setLoading(false);
                const message = `Please enter the code sent to ${phoneNumber}`;
                setShowOTP(true);
                setMessage(message);
            }
        })
        .catch((err)=>{
			setLoading(false)
            console.log(err);
        })
    }

	const generateOTPViaWhatsapp = () =>{
		if(!phoneNumber) return message.error("Enter phone number to continue");
		setLoading(true);
        // axios.get(`${process.env.REACT_APP_BASE_URL}/api/v2/generate_phone_verification_token_wa?phone_number=${phoneNo}`)
		axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/send_whatsapp_otp`, {
			phoneNo: phoneNumber,
			email: merchant.email	
		})
        .then((res)=>{
            if(res.status === 200){
                setLoading(false);
                const message = `Please enter the code sent to ${phoneNumber}`;
				setIsWhatsapp(true);
                setShowOTP(true);
                setMessage(message);
            }
        })
        .catch((err)=>{
			setLoading(false)
            console.log(err);
        })
    }//

	const confirmWhatsappOtp = () =>{
		if(!OTP) return message.error("Enter otp to continue");
		if(!phoneNumber) return message.error("Enter phone number to continue");
        
		setLoading(true);
        // axios.get(`${process.env.REACT_APP_BASE_URL}/api/v2/generate_phone_verification_token_wa?phone_number=${phoneNo}`)
		axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/verify_whatsapp_otp`, {
			phoneNo: phoneNumber,
			email: merchant.email,
			otp: OTP	
		})
        .then((res)=>{
            if(res.status === 200){
                setLoading(false);
				if(!isUserFound){
					setMessage(null);
					return handleSignUp();
				}
                setUser(userData);
                localStorage.setItem("widget_user_booking", JSON.stringify(userData));
				return onCancel();
            }
        })
        .catch((err)=>{
			setLoading(false)
            console.log(err);
        })
    }

	const handleConfirmOTP = ( ) =>{
		// const { phone_number } = signUpForm.getFieldsValue();
        if(!OTP) return message.error("Enter otp to continue");
		if(!phoneNumber) return message.error("Enter phone number to continue");
        const formData = new FormData();
        formData.append("phone_number", phoneNumber);
        formData.append("code", OTP);
		if(isWhatapp){
			return confirmWhatsappOtp();
		}
		setLoading(true);
        axios.put(`${process.env.REACT_APP_BASE_URL}/api/v2/verify_merchant_phone_number`, formData)
        .then((res)=>{
            if(res.status === 200){
                setLoading(false)
                setMessage(res.data.message);
				if(!isUserFound){
					setMessage(null);
					return handleSignUp();
				}
                setUser(userData);
                localStorage.setItem("widget_user_booking", JSON.stringify(userData));
				onCancel();
            }
        })
        .catch((err)=>{
            setLoading(false);
            setErrorMsg(err?.response?.data?.message || err.message);
        })
    }

  return (
    <Modal
        open={open}
        onCancel={onCancel}
        title={`${user && user.first_name ? `Welcome ${user.first_name}!` : "Enter Your Phone number to continue"}`}
        footer={
            <div style={{display: "flex", gap: 10, justifyContent: "end"}}>
                {!isNewUser && <Button type="primary" loading={loading} onClick={() => showOTP ? handleConfirmOTP() :  handleSignIn()}>
                    Continue
                </Button>}
                {isNewUser && <Button type="primary" loading={loading} onClick={handleSignUp}>
                    Contiue
                </Button>}
            </div>
        }
    >
        <Form form={form} layout="vertical" style={{ margin: "20px 0"}}>
            {messages && <p className='message'>{messages}</p>}
            {errorMsg && <p className='error'>{errorMsg}</p>}
            {isNewUser && <FormItem name="name">
                <Input 
                    placeholder='Enter your name'
                    onChange={(e) => setName(e.target.value)}
                    style={{height: 40, border: "0.5px solid rgb(107, 106, 106)"}}
                />
            </FormItem>}
            <FormItem name="phone_number" rules={[
                {required: true}
            ]}>
                <PhoneInput
                    inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
                    inputStyle={{
                        border: "0.5px solid #6B6A6A",
                        height: "40px",
                        width: "100%",
                        borderRadius: 2,
                    }}
                    placeholder={user &&user.phone_number}
                    country="us"
                    value={phoneNumber }
                    onChange={(value)=>{
                        setPhoneNumber(`+${value}`)
                    }}
                />
            </FormItem>
           {showOTP && <FormItem name="otp" rules={[
                {required: true}
            ]}>
                <InputNumber 
                    placeholder='otp'
                    style={{height: 40, border: "0.5px solid rgb(107, 106, 106)", width: "100%"}}
                    onChange={(value) => setOTP(value)}
                />
            </FormItem>}
        </Form>
        {!showSendWhatsapp && showOTP && <p style={{color: "#000", textAlign: "center"}}>{formatTime(timeLeft)}</p>}
        {showSendWhatsapp && <p>Didn't receieve OTP, <span onClick={generateOTPViaWhatsapp} style={{cursor: "pointer"}}>Resend OTP via Whatsapp</span> </p>}
    </Modal>
  )
}

export default SigninUser