import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FETCH_MERCHANT_DETAILS, CREATE_BOOKING } from '../Graphql/Queries';
import { Button, Card, Col, Input, message, Row, Steps, DatePicker, Select, Radio, Image, Modal, Tooltip } from "antd";
import SigninUser from '../components/modals/SigninUser';
import axios from "axios";
import { Rectangle } from '../assets';
import { usePaystackPayment } from "react-paystack";
import { EditFilled, LoadingOutlined, MinusOutlined, PlusCircleOutlined, PlusOutlined  } from "@ant-design/icons";
import "./index.css";

const daysOfTheWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday"
];
const Option = Select.Option;
const BookingRoute = () => {
    const params = useParams();
    const location = useLocation();
    const [fetchMerchantDetails] = useLazyQuery(FETCH_MERCHANT_DETAILS);
    const [ merchantDetails, setMerchantDetails ] = useState(null);
    const [ userDetails, setUserDetails ] = useState(null);
    const [ shopLink, setShopLink ] = useState(null);
    const [ openPhoneModal, setOpenPhoneModal ] = useState(false);
    const [ merchantLoading, setMerchantLoading ] = useState(false);
    const [ services, setServices ] = useState([]);
    const [ searchedServies, setSearchedServices ] = useState([]);
    const [ selectedService, setSelectedServices ] = useState([]);
    const [ selectedServiceId, setSelectedServicesId ] = useState([]);
    const [ serviceLoading, setServiceLoading ] = useState(false);
    const [current, setCurrent] = useState(0);
    const [ workHours, setWorkHours ] = useState([]);
    const [ selectedDate, setSelectedDate ] = useState(null);
    const [ gettingWorkHours, setGettingWorkHours ] = useState(false);
    const [ selectedBranch, setSelectedBranch ] = useState(null);
    const [ branchStaffLoading, setBranchStaffLoading ] = useState(false);
    const [ merchantBranches, setMerchantBranches ] = useState([]);
    const [ selectedStaff, setSelectedStaff ] = useState(null);
    const [ staffOfBranch, setStaffOfBranch ] = useState([]);
    const [ branchId, setBranchID ] = useState({branch_id: null,
        branch_name: null
    });
    const [ branchAddress, setBranchAddress ] = useState(null);
    const [ repeat, setRepeat ] = useState(false);
    const [ repeat_frequency, setRepeat_frequency ] = useState(null);
    const [ repeat_number, setRepeat_number ] = useState(null);
    const [ color, setColor ] = useState({
        primary: "#fff",
        secondary: "#1677ff"
    })
    ///////////////////////////payment////////////////////////
    // const [ openBankModal, setOpenBankModal ] = useState(false);
    const [ PayStackLoading, setPayStackLoading] = useState(false);
    const [payStackKey, setPayStackKey] = useState(null);
    const [ gettingPayStack, setgettingPayStack ] = useState(false);
    const [ isPayStack, setIsPaystack ] = useState(false);
    const [ isPaymentSuccessful, setIsPaymentSuccessful ] = useState(false);
    const [createBooking, { loading: isCreateBookingLoading }] = useMutation(CREATE_BOOKING);

    useEffect(() => {
        const localuser = localStorage.getItem("widget_user_booking");
        if(localuser) setUserDetails(JSON.parse(localuser));
        if(!localuser) setOpenPhoneModal(true);
    }, []);

    const calculateTotal = (selectedService) => {
        const total = selectedService.reduce((prev, curr)=> {
            return prev + (Number(curr.amount_charged) * Number(curr.quantity))
        }, 0);
        return Number(total).toFixed(2);
    }

    const paystackConfig = {
        reference: new Date(Date.now()).getTime().toString(),
        email: merchantDetails && merchantDetails.email,
        currency: merchantDetails && merchantDetails.currency,
        amount: calculateTotal(selectedService) * 100,
        publicKey: payStackKey ? payStackKey : process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    };

    const paymentCallback = (resp) => {
        if (resp.message === "Approved") {
          setPayStackLoading(false);
          setIsPaymentSuccessful(true);
          message.success("Payment is successful!");
          handleCreateBooking();
        } else {
            message.error(`payment not successful, please try again!`);
        }
    };

    useEffect(() => {
        if(location && location.search){
            const colors =  location.search
            .split(/[?&]/)         
            .filter(Boolean)  
            .map(part => part.split('=')[1]);

            setColor({
                primary: colors.length > 0 ? `#${colors[0]}` : color.primary,
                secondary: colors.length > 1 ? `#${colors[1]}` : color.secondary
            })
        }
    }, [location]);
    useEffect(() => {
        const { shop_link } = params;
        if(shop_link) {
            setShopLink(shop_link)
            handleFetchMerchantDetails(shop_link)};
    }, [params, location]);

    useEffect(() => {
        selectedBranch && setStaffOfBranch(() => {
            const branchFound = merchantBranches && merchantBranches.find(branc => branc.id == selectedBranch);
            setBranchID(() => {
                return {
                    branch_id: branchFound.id,
                    branch_name: branchFound.name
                }
            })
            setBranchAddress(() => {
                return `${branchFound.address_line1 ? branchFound.address_line1 : ""}${branchFound.address_line2 ? ", " +branchFound.address_line2 : ""}`
            })
            return branchFound.staff;
        })
    }, [selectedBranch, merchantBranches]);

    const handlePayStack = usePaystackPayment(paystackConfig);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const handleFetchMerchantDetails = (shoplink) => {
        setMerchantLoading(true);
        fetchMerchantDetails({
            variables: { uRewardsId: shoplink },
            onCompleted: (data) => {
                const { fetchMerchantDetails } = data;
                if(fetchMerchantDetails === "Merchant not found") return message.error("Oops!, this merchant does not exist!")
                const merchant = JSON.parse(fetchMerchantDetails);
                const branches = merchant.merchant_details.business_branches
                setMerchantDetails(merchant.merchant_details);
                const homeBranch = branches.filter((branch) => {
                    return branch.name.toLowerCase().includes("home");
                });

                setMerchantLoading(false);
                handleGetMerchantServices(merchant.merchant_details.id);
                handleGetMerchantWorkHours(merchant.merchant_details.id);
                handleGetMerchantStaffDetails(merchant.merchant_details.email, homeBranch[0].id);
                handleGetMerchantApp(merchant.merchant_details.email);
                handleGetMerchantPayStackDetails(merchant.merchant_details.email)
            },
            onError: (error) => {
                message.error(error.message);
                setMerchantLoading(false);
            }
        })
    }

    const handleGetMerchantServices = async (id) => {
        setServiceLoading(true);
        axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/get_merchant_services`, { merchant_id: id })
        .then((res)=>{
            setServiceLoading(false);
            setServices(res.data.services);
            setSearchedServices(res.data.services)
        })
        .catch((err)=> {
            setServiceLoading(false);
            console.log(err);
            message.error("unable to fetch services, please try again")
        })
    }

    const handleGetMerchantWorkHours = async (id) => {
        const merchant_id  = id;
        setGettingWorkHours(true);
        axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/get_merchant_work_hours`, { merchant_id })
        .then((res)=>{
            setGettingWorkHours(false);
            console.log(gettingWorkHours);
            setWorkHours(res.data.workHours);
        })
        .catch((err)=> {
            setGettingWorkHours(false);
            console.log(err);
            message.error("unable to fetch services, please try again")
        })
    }

    const handleGetMerchantStaffDetails = async (merchant_email, branch_id) => {  
        setBranchStaffLoading(true);
        axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/get_merchant_branch_staffdetails`, { merchant_email, branch_id })
        .then((res)=>{
            setBranchStaffLoading(false);
            setMerchantBranches(res.data.branches)
        })
        .catch((err)=> {
            setBranchStaffLoading(false);
            console.log(err);
            // message.error("unable to fetch services, please try again")
        })
    }

    const handleGetMerchantApp = async ( email ) => {
        await axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/urewards_merchant_app`, { merchant_email: email }, {
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then((response)=>{
            if(response.status === 200){
                const apps = response.data.data;

                const paystack = apps.filter((app)=>{
                    return app.app.app_name.toLowerCase().includes("paystack")
                });
            
                paystack.length > 0 
                ? setIsPaystack(true)
                    : setIsPaystack(false);
            }
        })
        .catch((error)=>{
            return message.error(error);
        })
    }

    const handleGetMerchantPayStackDetails = async (email) => {
        setgettingPayStack(true);
        axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/urewards_merchant_paystack_details`,
            { merchant_email: email },)
        .then((response) => {
            if (response.status === 200) {
                setgettingPayStack(false);
                setPayStackKey(response.data.data.data);
            }
        })
        .catch((err) => {
            setgettingPayStack(false);
            message.error(`${err.response.statusText}`);
        });
    };

    const handleSearchServices = (value) => {
        if(value === "") return setSearchedServices(services);
        const searched = services.filter(service => service.service_name.toLowerCase().includes(value.toLowerCase()));
        setSearchedServices(searched);
    }

    const handleSelectService = (services) => {
        const newService = {...services};
        let quantity;
        if(!services.quantity){
            newService.quantity = 1;
            quantity = 1
        }else{
            newService.quantity = Number(services.quantity) + 1;
            quantity = Number(services.quantity) + 1;
        }
        
        const isSelectedServices = selectedService.find(service => service.id === services.id);

        if(isSelectedServices){
            setSelectedServices((prev)=>{
                return [
                    ...prev.map((serv)=>{
                        if(serv.id === services.id){
                            serv.quantity = quantity;
                            return serv;
                        }else{
                            return serv;
                        }
                    })
                ]
            })
        }else{
            setSelectedServices((prev)=>{
                return [...prev, newService]
            });
            setSelectedServicesId((prev)=>{
                return [...prev, services.id]
            })
        }

        setSearchedServices((prev)=>{
            return [
                ...prev.map((servic)=>{
                    if(servic.id === services.id){
                        servic.quantity = quantity;
                        return servic;
                    }else{
                        return servic;
                    }
                })
            ]
        })
    } 

    const handleSelectStaff = (staffid) => {
        if(staffid === "any"){
            setSelectedStaff(()=>{
                return {
                    staff_name: "any",
                    staff_email: null,
                    staff_id: null
                }  
            })
        }else{
            const staffToFind = staffOfBranch.length > 0 && staffOfBranch.find(staff=>staff.id == staffid);
            setSelectedStaff(()=>{
                return {
                    staff_name: staffToFind.username,
                    staff_email: staffToFind.email,
                    staff_id: staffToFind.id
                }
            })
        }
    }

    const handleSelectServiceDecrease = (services) => {

        if(services.quantity <= 1){
            setSelectedServicesId((prev)=>{
                return [
                    ...prev.filter((serv)=>serv !== services.id)
                ]
            })

            setSelectedServices((prev)=>{
                return [
                    ...prev.filter((serv)=>serv.id !== services.id)
                ]
            })

            setSearchedServices((prev)=>{
                return [
                    ...prev.map((servic)=>{
                        if(servic.id === services.id){
                            delete servic.quantity;
                            return servic;
                        }else{
                            return servic;
                        }
                    })
                ]
            })
            
        }else{
            let quantity = services.quantity;
            setSelectedServices((prev)=>{
                return [
                    ...prev.map((serv)=>{
                        if(serv.id === services.id){
                            serv.quantity = quantity - 1;
                            return serv;
                        }else{
                            return serv;
                        }
                    })
                ]
            })

            setSearchedServices((prev)=>{
                return [
                    ...prev.map((servic)=>{
                        if(servic.id === services.id){
                            servic.quantity = quantity - 1;
                            return servic;
                        }else{
                            return servic;
                        }
                    })
                ]
            })
        }
    }

    const handleOk = () => {
        if(!selectedDate) return message.error("select date to continue");
        if(!selectedStaff) return message.error("select staff to continue");

        const todaysDate = new Date(Date.now()).getTime();
        
        if(new Date(selectedDate).getTime() < todaysDate){
            return message.error("Cannot book past date!");
        }
        
        if(repeat){
            if(!repeat_frequency) return message.error("select repeat frequency");
            if(!repeat_number) return message.error("select repeat frequency")
        }
        next();
    }

    const handleCreateBooking = () => {
        const merchant_id = merchantDetails.id;
        const merchant_email = merchantDetails.email;
        const merchant_name = merchantDetails.business_name;
        const first_name = merchantDetails.first_name;

        selectedService.map((servi, index) => {
            const completion_time = servi.completion_time || servi.duration;
            const service_id = servi.id;
            delete servi.image_url;
            delete servi.location;
            delete servi.id;
            delete servi.description;
            delete servi.online_booking;
            delete servi.deleted_at;
            delete servi.created_at;
            delete servi.duration;
            delete servi.updated_at;
            delete servi.service_type;
            delete servi.quantity;

            const createBookingObj = {
                ...servi,
                customer_id: Number(userDetails.user_id),
                customer_phone_number: userDetails.phone_number,
                customer_email: userDetails.email,
                service_id,
                ...selectedStaff,
                repeat,
                repeat_frequency,
                repeat_number, 
                booking_date: selectedDate.format("DD-MM-YYYY   hh:mma"),
                completion_time,
                branch_id: branchId.branch_id,
                branch_name: branchId.branch_name,
                merchant_id,
                merchant_email,
                first_name,
                merchant_name,
                payment_status: isPaymentSuccessful ? "paid" : "not paid"
            }

            return createBooking({ 
                variables: { data: createBookingObj },
                onCompleted: (data) => {
                    if(index === selectedService.length - 1){
                        Modal.success({
                            title: `Booking created successfully! Kindly visit ${branchAddress}.`,
                            onOk: () => {
                                setCurrent(0);
                                setSelectedServices([]);
                                setSelectedServicesId([]);
                            }
                        });
                    }
                    
                },
                onError: (error) => {
                    index === selectedService.length - 1 && Modal.error({
                        title: error.message,
                    });
                } 
            });
        });

    }

    const radioOptions =  [
        { label: "Yes", value: true },
        { label: "No", value: false },
    ]

    const steps = [
        {
          title: 'Select Bookings',
          content: (
            serviceLoading ? <LoadingOutlined spin style={{fontSize: 20}} />  : 
            <Row gutter={[15,15]}>
                <Col lg={24} sm={24} xs={24} style={{display: "flex", justifyContent: "center", position: "sticky", top: 80, backgroundColor: color.primary, zIndex:1, padding: "5px 0 10px"}}>
                    <Input 
                        style={{width: "80%", height: 40, border: "0.5px solid", borderColor: color.secondary}}
                        placeholder='Search Services....'
                        onChange={(e) => handleSearchServices(e.target.value)}
                    />
                </Col>
                <Col lg={16} sm={24} xs={24}>
                    <Row gutter={[15,15]}>
                        {searchedServies.length > 0 && searchedServies.map((service, index) => {
                        return (
                            <Col lg={24} sm={12} xs={24} key={index} className='item_list' style={{borderColor: color.secondary}}>
                                <div className='item_start'>
                                    <Image src={service.image_url || Rectangle} alt={service.service_name} style={{height:80, width:80}} />
                                    <div className='list_info'>
                                        <b style={{ wordBreak: "break-all", color:color.secondary }}>
                                            {service.service_name}
                                        </b>
                                        <b style={{display: "flex", alignItems: "center", color: color.secondary}}>{service.completion_time || service.duration} mins | {merchantDetails && merchantDetails.currency} {service.amount_charged}</b>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        paddingBottom: 10
                                    }}
                                >
                                    {selectedServiceId.includes(service.id) ? 
                                        <div className="plusandminus">
                                            <MinusOutlined  onClick={()=> handleSelectServiceDecrease(service)} style={{fontSize: 15, color: color.secondary, cursor: "pointer"}} /> 
                                            <p className='quantity'>{service.quantity}</p>
                                            <PlusOutlined 
                                                onClick={()=> handleSelectService(service)} style={{fontSize: 15, color: color.secondary, cursor: "pointer"}} />
                                        </div>
                                    : <Button
                                        type="link"
                                        onClick={() => {
                                            handleSelectService(service)
                                        }}
                                        // disabled={product.variants.length !== 0}
                                    >
                                        <PlusCircleOutlined style={{fontSize: 30, color: color.secondary, }} />
                                    </Button>}
                                </div>
                        
                            </Col>
                            )
                        })}
                    </Row>
                </Col>
               
                <Col lg={8} sm={24} xs={24} className='stcky_summary'>
                    <Card 
                        styles={{ body: { padding: 10, borderColor: color.secondary,  }}}
                        title={<p style={{fontSize: 24, textAlign: "center", color: color.secondary}}>Service Summary</p>}
                        style={{borderColor: color.secondary, backgroundColor: color.primary, zIndex:1, padding: "5px 0 10px"}}
                    >
                        {selectedService.length === 0 && <p style={{fontSize: 20, textAlign: "center", color: color.secondary}}>No Selected Service</p>}
                        {selectedService.length > 0 && <Row gutter={[5, 5]} >
                            {selectedService.map((service, index) => {
                                return (
                                    <Col lg={24} sm={24} xs={24} className='summary_total' key={index}>
                                        <div style={{display: "flex", alignItems: "center", gap:15}}>
                                            <Image src={service.image_url || Rectangle} alt={service.service_name} style={{height: 50, width:50}} />
                                            <h5 style={{color: color.secondary}}>{service.service_name}</h5>
                                        </div>
                                    
                                        <div className="lowerdiv">
                                            <p style={{color: color.secondary}}>{service.completion_time || service.duration} mins | {Number(service.amount_charged).toFixed(2)}</p>
                                            <p style={{color: color.secondary}}><b>x {service.quantity}</b></p>
                                        </div>
                                    </Col>
                                )
                            })}
                             <hr style={{ width: "100%" }} />
                            <Col lg={24} sm={24} xs={24} style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                                <b style={{color: color.secondary}}>Total:</b>
                                <p style={{margin: 0, color: color.secondary}}>{ merchantDetails && merchantDetails.currency} {calculateTotal(selectedService)}</p>
                            </Col>
                        </Row>}
                    </Card>
                </Col>
            </Row>
          ),
        },
        {
          title: 'Book Appointment Date and Select Staff',
          content: (
            <Row gutter={[]} style={{paddingBottom: 180}}>
                <Col lg={24} sm={24} xs={24}>
                    <h3 style={{ color: "#000", margin: 0}}>Book Date</h3>
                    <DatePicker 
                        format="DD-MM-YYYY   hh:mma"
                        size="large"
                        style={{width: "100%", height: 40, border: "0.5px solid", borderColor: color.secondary}}
                        showTime
                        showNow={false}
                        // value={selectedDate ? moment(selectedDate) : ""}
                        onChange={(value)=>{
                            if(workHours && workHours.length > 0){
                                const selectedDOFW = daysOfTheWeek[new Date(value).getDay()];
                                const keys = Object.keys(workHours[0]);
                                const values = Object.values(workHours[0]);
                                const choseDay = keys && keys.find(day => day === selectedDOFW);
                                const choseValue = values[keys.indexOf(choseDay)];
                                if(choseValue === "") return message.info("This day is not available");
                                setSelectedDate(value);
                            }else{
                                setSelectedDate(value);
                            }
                        }}
                    />
                </Col>

                <Col lg={24} sm={24} xs={24}>
                    <h3 style={{ color: "#000", margin: 0, marginTop: "20px"}} >Select Business Branch</h3>
                    <Select
                        style={{width: "100%", height: 40, border: "0.5px solid", borderColor: color.secondary}}
                        size='large'
                        placeholder="select your preferred branch"
                        value={selectedBranch && selectedBranch}
                        onChange={(value)=>{
                            setSelectedBranch(value);
                            // handleGetMerchantStaffDetails(merchantDetails.email, value)
                        }}
                        // defaultValue={selectedBranch}
                        loading={branchStaffLoading}
                    >
                        {merchantBranches && merchantBranches.length > 0 && 
                            merchantBranches.map((branch, index)=>{
                                return <Option key={index} value={branch.id}>{branch.name}</Option>
                            })
                        }
                    </Select>
                </Col>

                <Col lg={24} sm={24} xs={24}>
                    <h3 style={{ color: "#000", margin: 0, marginTop: "20px"}} >Select Staff</h3>
                    <Select
                        style={{width: "100%", height: 40, border: "0.5px solid", borderColor: color.secondary}}
                        size='large'
                        placeholder="select your preferred staff"
                        value={selectedStaff && selectedStaff.staff_name}
                        onChange={(value)=>handleSelectStaff(value)}
                        loading={branchStaffLoading}
                    >
                        <Option key="any">Any</Option>
                        {staffOfBranch.map((stf, index)=>{
                            return <Option key={index} value={stf.id}>{stf.username}</Option>
                        })
                        }
                    </Select>
                   
                </Col>
                <Col lg={24} sm={24} xs={24}>
                    <h3 style={{ color: "#000", margin: 0, marginTop: "20px"}} >Repeat booking</h3>
                    <Radio.Group 
                        value={repeat}
                        className="radioBooking"
                        options={radioOptions}
                        onChange={(e)=>{setRepeat(e.target.value)}}
                    />
                </Col>
                {repeat && <Col lg={24} sm={24} xs={24}>
                    <p style={{ color: "#000", marginTop: "20px"}}>
                        <b>Repeat every</b> <br />
                        <Input 
                            size="large" 
                            onChange={(e)=>setRepeat_frequency(e.target.value)}
                            style={{width: "30%"}}
                        /> week(s)
                    </p>
                    <p style={{ color: "#000", marginTop: "20px"}}>
                        <b>Repeat</b> <br />
                        <Input 
                            size="large" 
                            onChange={(e)=>setRepeat_number(e.target.value)}
                            style={{width: "30%"}}
                        /> time(s)
                    </p>
                </Col>}
            </Row>
          ),
        },
        {
          title: 'Pay and Checkout',
          content: (
            <Row gutter={[15,15]} style={{marginBottom: 40 }}>
                <Col lg={24} sm={24} xs={24}>
                    <Row gutter={[15, 15]} className='payment_view'>
                        {selectedService.map((service, index) => {
                            return (
                                <Col lg={24} sm={24} xs={24} className='summary_total' key={index}>
                                    <div style={{display: "flex", alignItems: "center", gap:15}}>
                                        <Image src={service.image_url || Rectangle} alt={service.service_name} style={{height: 50, width:50}} />
                                        <h3>{service.service_name}</h3>
                                    </div>
                                
                                    <div className="lowerdiv">
                                        <p>{service.completion_time || service.duration} mins | {Number(service.amount_charged).toFixed(2)}</p>
                                        <p><b>x {service.quantity}</b></p>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Col>
              
                <hr style={{ width: "100%" }} />
                <Col lg={24} sm={24} xs={24} style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <b >Total:</b>
                    <p style={{margin: 0}}>{ merchantDetails && merchantDetails.currency} {calculateTotal(selectedService)}</p>
                </Col>
                <Col lg={24} sm={24} xs={24}>
                <div 
                    style={{
                            display: "flex",
                            flexDirection: "column",
                            gap:"5px",
                            alignItems: "center"
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={()=>handleCreateBooking()}
                            className="button"
                            loading={isCreateBookingLoading}
                        >
                            Book without payment
                        </Button>
                        {isPayStack && <Button
                            type="primary"
                            loading={gettingPayStack || PayStackLoading}
                            onClick={()=> handlePayStack(paymentCallback, () => {})}
                            className="button"
                        >
                            Pay with Paystack
                        </Button>}
                        {/* {merchantDetails?.currency === "NGN" 
                            &&  <Button
                            type="primary"
                            style={{
                                display: `${merchantDetails?.payout_active ? "block" : "none"}`,
                            }}
                            className="button"
                            onClick={()=>{
                                setOpenBankModal(true);
                            }}
                        >
                            Pay with USSD
                        </Button>} */}
                    </div>
                </Col>
            </Row>
          ),
        },
    ];
    const items = steps.map((item) => ({ key: item.title, title: item.title }));
   
    // const showCard = selectedService.length > 0 && current !== 2
  return (
    <>
        <Card loading={merchantLoading} styles={{body: { paddingTop: 0, backgroundColor: color.primary }}}>
            <div style={{position: "sticky", top: 0, backgroundColor: color.primary, zIndex:1, padding: "10px 0"}}>
                {userDetails && <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", height:35}}>
                    <p style={{textTransform: "capitalize", fontSize:20, fontWeight: 600}}>Welcome {userDetails.first_name}!</p>
                    <Tooltip title="Change user details">
                        <EditFilled style={{color: color.secondary, fontSize: 20, cursor: "pointer"}} onClick={() => setOpenPhoneModal(true)} />
                    </Tooltip>  
                </div>}
                <Steps current={current} items={items} style={{backgroundColor: color.primary}} />
            </div>
            <div style={{margin: "10px 0"}}>{steps[current].content}</div>

            {/* {showCard && <Card title={null} className='summarycard'>
                <Row gutter={[0, 0]}>
                    {selectedService.map((service, index) => {
                        return (
                            <Col lg={24} sm={24} xs={24} className='summary' key={index}>
                                <h3>{service.service_name}</h3>
                                <div className="lowerdiv">
                                    <p>{service.completion_time || service.duration} mins | {Number(service.amount_charged).toFixed(2)}</p>
                                    <p><b>x {service.quantity}</b></p>
                                </div>
                            </Col>
                        )
                    })}
                    <hr style={{ width: "100%" }} />
                    <Col lg={24} sm={24} xs={24} style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        <b >Total:</b>
                        <p style={{margin: 0}}>{ merchantDetails && merchantDetails.currency} {calculateTotal(selectedService)}</p>
                    </Col>
                </Row>

            </Card>} */}
            {!serviceLoading && <div style={{display: "flex", justifyContent: "space-between", position: "fixed", bottom: 0, backgroundColor: color.primary, padding: "10px 0", width: "98%"}}>
                {<Button type='primary' disabled={current === 0} onClick={() => prev()}>
                    Back
                </Button>}
                
                {current < steps.length - 1 && <Button type='primary' onClick={() => current === 0 ? next() : handleOk()} disabled={selectedService.length === 0}>
                    Next
                </Button>}
                {/* {current === steps.length - 1 && steps.length - 1 && <Button type='primary'>
                    Done
                </Button>} */}
            </div>}

        </Card>
        {openPhoneModal && (
            <SigninUser 
                open={openPhoneModal}
                onCancel={() => setOpenPhoneModal(false)}
                user={userDetails}
                setUser={setUserDetails}
                merchant={merchantDetails}
                shopLink={shopLink}
            />
        )}
    </>
    
  )
}

export default BookingRoute