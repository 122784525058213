import { gql } from "@apollo/client";

export const FETCH_MERCHANT_DETAILS = gql`
	query FetchMerchantDetails($uRewardsId: String) {
		fetchMerchantDetails(uRewardsId: $uRewardsId)
	}
`;

export const CREATE_BOOKING = gql`
  mutation createBookingOnLoystar($data: appointmentInputLoystar!) {
    createBookingOnLoystar(data: $data) {
      service_name
      customer_id
	  customer_phone_number
	  customer_email
      staff_name
      staff_id
      staff_email
      booking_date
      status
      payment_status
      amount_charged
      completion_time
      loyalty_id
      branch_name
      branch_id
      repeat
      repeat_frequency
      repeat_number
      service_id
      created_at
      id
    }
  }
`;

export const SIGNUP_USER = gql`
	mutation SignUpUser($data: NewUserInput) {
		signUpUser(data: $data) {
			id
			user_id
			merchant_id
			first_name
			last_name
			email
			password
			state
			phone_number
			address_line1
			sex
			date_of_birth
		}
	}
`;

export const GET_USERS_BY_PHONE = gql`
  query getUserByPhone( $phone_number: String, $merchant_id: Int ){
	getUserByPhone( phone_number: $phone_number, merchant_id: $merchant_id ){
		message
		user{
			id
			user_id
			first_name
			email
			state
			phone_number
			sex
			address_line1
			address_line2
			country
			postal_code
			longitude
			latitude
			loyalty_points {
				simple_points {
					accumulated_points
					merchant_loyalty_program_id
					program_name
					threshold
					reward
					program_type
				}
				stamps_program {
					accumulated_stamps
					merchant_loyalty_program_id
					program_name
					threshold
					reward
					program_type
				}
			}
		}
	}
  }
`